&--number {
  @extend %base-input;

  > input[type="number"] {
    @extend %input;
  }

  %stepper {
    cursor: pointer;

    // Stop from altering size
    flex-grow: 0;
    flex-shrink: 0;

    border-left-style: solid;

    width: 5.9rem;

    position: relative;
    svg {
      fill: currentcolor;
      height: 15%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .stepper--up {
    @extend %stepper;

    svg {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  .stepper--down {
    @extend %stepper;
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    > input[type="number"] {
      color: $regular-input;
    }

    .stepper--up, .stepper--down {
      border-width: inherit;
      border-color: inherit;
      svg {
        fill: $regular-border;
      }
    }
  }

  // Compact
  &.-compact {
    > input[type="number"] {
      color: $compact-input;
    }

    .stepper--up, .stepper--down {
      border-width: 1px;
      border-color: $compact-border;
      svg {
        fill: $compact-border;
      }
    }
  }
}
