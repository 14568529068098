&--ghosted {
  @include button-variation($ghosted-regular-text, $ghosted-regular-border, $ghosted-regular-background);
  box-shadow: inset 0 0 0 2px $ghosted-regular-border;
  @include font-600;

  &.-primary {
    @include button-variation($ghosted-primary-text, $ghosted-primary-border, $ghosted-primary-background);
    box-shadow: inset 0 0 0 2px $ghosted-primary-border;
  }

  &.-highlighted {
    @include button-variation($ghosted-highlighted-text, $ghosted-highlighted-border, $ghosted-highlighted-background);
    box-shadow: inset 0 0 0 2px $ghosted-highlighted-border;
  }

  &.-danger {
    @include button-variation($ghosted-danger-text, $ghosted-danger-border, $ghosted-danger-background);
    box-shadow: inset 0 0 0 2px $ghosted-danger-border;
  }

  &[disabled] {
    @include button-variation($ghosted-disabled-text, $ghosted-disabled-border, $ghosted-disabled-background);
    box-shadow: inset 0 0 0 2px $ghosted-disabled-border;
  }
}
