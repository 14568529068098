&--tags {
  @extend %base-input;

  padding: .5em .5em .5em .5em;
  flex-wrap: wrap;
  align-items: center;
  align-content: start;

  > input[type="text"], .tag {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  > input[type="text"] {
    @extend %input;
    padding: .125em 0; // reset from %base placeholder
    flex-grow: 1;
    width: 80px
  }

  .tag {
    font-size: .75em;
    margin-right: .5em;

    display: flex;
    align-items: center;

    padding: .5em;
    border-radius: .125em;

    background: black;
    color: white;

    .text {
      margin-right: .5em;
    }
    svg.icon {
      height: .75em;
      fill: currentcolor;
      cursor: pointer;
    }
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    > input[type="text"] {
      color: $regular-input;
    }
    .tag {
      background: $regular-input;
      color: $regular-background-active;
    }
  }

  // Compact
  &.-compact {
    > input[type="text"] {
      color: $compact-input;
    }
    .tag {
      background: $compact-input;
      color: $compact-background-active;
    }
  }
}
