&--text {
  @extend %base-input;

  > input {
    @extend %input;
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    > input {
      color: $regular-input;
    }
  }

  // Compact
  &.-compact {
    > input {
      color: $compact-input;
    }
  }
}
