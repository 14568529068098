&--alert {
  z-index: 99;
  position: sticky;
  top: 0;

  padding: 1em;
  width: 100%;
  box-sizing: border-box;

  @include font-400;
  font-size: 2em;
  color: $alert-text;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 1.5em;
  }

  background: $alert-background;

  .text {
    margin-right: 2em;
  }

  svg.icon {
    cursor: pointer;
    height: .75em;
    fill: $alert-text;

    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
  }
}
