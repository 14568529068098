&--card {
  z-index: 99;
  position: fixed;
  font-size: 1.3em;
  bottom: 1em;
  right: 1em;
  left: 1em;

  box-shadow: $card-shadow;

  @media (min-width: 400px) {
    left: auto;
    width: 300px;
  }

  padding: 1em;
  // border: 2px solid $card-border;
  border-radius: .3em;
  background-color: $card-background;

  display: flex;
  align-items: start;

  .img, .icon {
    flex-shrink: 0;
  }

  p {
    @include font-400;
    color: $card-text;
    font-size: 1.3rem;
    line-height: 1.25;

    margin-right: 1.5em;
  }

  .img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin-right: 1em;

    height: 3em;
    width: 3em;
    border-radius: .5em;
  }

  .icon {
    width: 1em;
    cursor: pointer;
    padding-top: 0;
    align-self: flex-start;
    fill: rgba(0,0,0,0.33);
  }
}
