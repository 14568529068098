&--login {
  z-index: 99;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;

  margin: 1em;
  padding: 1.66em;

  [class^="input"] {
    margin-bottom: .75em!important;
  }

  @media (min-width: 500px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
  }

  @include font-400;
  font-size: 1em;

  // border: 1px solid $login-border;
  border-radius: .3em;
  background-color: $login-background;
  box-shadow: $login-shadow;

  [class^="button"] {
    font-size: 2em!important;
  }

  .icon {
    cursor: pointer;
    height: 2em;
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    fill:rgba(0,0,0,0.33);
  }

  h3 {
    margin-bottom: 2em;
  }
}
