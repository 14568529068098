&--dropdown {
  @extend %base-input;

  .content {
    @extend %input;
    box-sizing: content-box;
    height: 1.25em;
  }

  .options {
    z-index: 2;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    margin: 0;
    width: 100%;

    .option {
      margin: 0;
      padding: .5em;

      font-size: 1em;

      cursor: pointer;

      border-bottom: inherit;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .icon {
    flex-shrink: 0;
    align-self: center;
    padding-right: 1em;
    height: .5em;
    path {
      transform: rotate(0deg);
      transform-origin: center;
    }
  }

  // SPECIAL STATES
  // ==================================================

  &[data-has-focus="false"] {
    .options {
      display: none;
    }
  }

  &[data-has-focus="true"] {
    .icon path {
      transform: rotate(180deg);
    }
  }

  &:focus {
    outline: none;
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    .content {
      color: $regular-input;
    }
    .options {
      background: $regular-background-active;
      color: $regular-input;
      border: inherit;
      left: -2px; // border width

      .option:hover {
        background: $regular-input;
        color: $regular-background-active;
      }
    }
    .icon {
      fill: $regular-label;
    }

    &[data-has-focus="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .options {
        border-bottom-left-radius: .2em;
        border-bottom-right-radius: .2em;
      }
    }
  }

  // Compact
  &.-compact {
    .content {
      color: $compact-input;
    }
    .options {
      background: $compact-background-active;
      color: $compact-input;
      border: 1px solid $compact-border-active;
      left: -1px; // border width

      .option:hover {
        background: $compact-background;
      }
    }
    .icon {
      fill: $compact-label;
    }
  }
}
