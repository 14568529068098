&--checkbox {
  @extend %base-input;

  .checkbox__container {
    width: 100%;
    padding: 1.25em .5em .5em .75em;
  }

  .checkbox__group {
    padding: .25em 0;
    position: relative;

    display: flex;
    align-items: center;

    font-size: .85em;

    label {
      margin-left: 1.5em;
    }

    .icon {
      position: absolute;
      svg {
        height: .75em;
        fill: currentcolor;
      }
      svg.empty { display: block }
      svg.checked { display: none }
    }
  }

  input[type="checkbox"] {
    @include invisible-input;
  }

  // SPECIAL STATES
  // ==================================================

  input[type="checkbox"]:checked {
    & ~ label {
      @include font-600;
    }
    & ~ .icon {
      svg.empty { display: none; }
      svg.checked { display: block }
    }
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    color: $regular-label;

    input[type="checkbox"]:checked {
      & ~ label {
        color: $regular-input;
      }
      & ~ .icon svg {
        fill: $regular-input;
      }
    }
  }

  // Compact
  &.-compact {
    color: $compact-label;

    input[type="checkbox"]:checked {
      & ~ label {
        color: $compact-input;
      }
      & ~ .icon svg {
        fill: $compact-input;
      }
    }
  }
}
