$regular: black;
$primary: black;
$highlighted: #00e4a0;
$highlightedOverWhite: #00dd9b;
$danger: #ff3b00;
$disabled: #ebe8e4;

// SOLID COLORS
// ==================================================

$solid-regular-background: $regular;
$solid-regular-border: $regular;
$solid-regular-text: white;

$solid-primary-background: $primary;
$solid-primary-border: $primary;
$solid-primary-text: white;

$solid-highlighted-background: $highlighted;
$solid-highlighted-border: $highlighted;
$solid-highlighted-text: white;

$solid-danger-background: $danger;
$solid-danger-border: $danger;
$solid-danger-text: white;

$solid-disabled-background: $disabled;
$solid-disabled-border: $disabled;
$solid-disabled-text: white;

// GHOSTED COLORS
// ==================================================

$ghosted-regular-background: transparent;
$ghosted-regular-border: $regular;
$ghosted-regular-text: $regular;

$ghosted-primary-background: transparent;
$ghosted-primary-border: $primary;
$ghosted-primary-text: $primary;

$ghosted-highlighted-background: transparent;
$ghosted-highlighted-border: $highlighted;
$ghosted-highlighted-text: $highlightedOverWhite;

$ghosted-danger-background: transparent;
$ghosted-danger-border: $danger;
$ghosted-danger-text: $danger;

$ghosted-disabled-background: transparent;
$ghosted-disabled-border: $disabled;
$ghosted-disabled-text: $disabled;

// OUTLINE COLORS
// ==================================================

$outline-regular-background: white;
$outline-regular-border: $regular;
$outline-regular-text: $regular;

$outline-disabled-background: white;
$outline-disabled-border: $disabled;
$outline-disabled-text: $disabled;

// UTILLITIES
// ==================================================

%base-button {
  @include button-reset;
  // @include font-400;
  font-size: 1em;

  padding: .5em .75em;
  border: 1px solid;
  border-radius: .25em;

  .icon {
    display: inline;
    height: 1em;
    padding: .125em;
    box-sizing: border-box;
    vertical-align: middle;
    fill: currentcolor;
    margin-bottom: .125em;
  }

  &.-block {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &.-icon-left {
    padding-left: .5em;
    svg {
      margin-right: .15em;
    }
  }
  &.-icon-left.-morespace {
    svg {
      margin: 0 .25em;
    }
  }

  &.-icon-right {
    padding-right: .5em;
    svg {
      margin-left: .125em;
    }
  }

  &.-icon-nudge-down {
    svg {
      position: relative;
      top: .125em;
    }
  }
}

@mixin button-variation($text, $border, $background) {
  @extend %base-button;
  border-color: $border;
  background: $background;
  color: $text;
}

// IMPORTS
// ==================================================

button.button, a.button {
  @import "solid";
  @import "ghosted";
  @import "outline";
}

// TEMP | CONTAINER FOR BUTTONS
// ==================================================

.button__container {
  display: flex;
  flex-wrap: wrap;

  >* {
    margin-right: .75em!important;
    margin-bottom: .75em!important;
  }
}
