&--solid {
  @include button-variation($solid-regular-text, $solid-regular-border, $solid-regular-background);
  @include font-400;

  &.-primary {
    @include button-variation($solid-primary-text, $solid-primary-border, $solid-primary-background);
  }

  &.-highlighted {
    @include button-variation($solid-highlighted-text, $solid-highlighted-border, $solid-highlighted-background);
  }

  &.-danger {
    @include button-variation($solid-danger-text, $solid-danger-border, $solid-danger-background);
  }

  &[disabled] {
    @include button-variation($solid-disabled-text, $solid-disabled-border, $solid-disabled-background);
  }
}
