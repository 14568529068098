// ALERT COLOR VARIABLES
// ==================================================

$alert-text: black;
$alert-background: #00e4a0;

// CARD COLOR VARIABLES
// ==================================================

$card-text: black;
$card-background: white;
$card-border: rgba(black, 0.13);
$card-shadow: 0px 0px 2em 0px rgba(black, .25);

// LOGIN COLOR VARIABLES
// ==================================================

$login-background: white;
$login-border: rgba(black, .33);;
$login-shadow: 0px 0px 10em 0px rgba(black, .33);

// IMPORTS
// ==================================================

.modal {
  @import "alert";
  @import "card";
  @import "login";
}
