&--textarea {
  @extend %base-input;

  .textarea {
    @extend %input;
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    .textarea {
      color: $regular-input;
    }
  }

  // Compact
  &.-compact {

    .textarea {
      color: $compact-input;
    }

    // NOTE
    // Fix grow of css-grid column
    min-width: 0;
    .textarea {
      max-width: 100%;
    }
    // END
  }
}
