table.custom-table {
  // TEMP
  margin: 5rem 0;
  // END

  font-size: 1rem;
  text-align: left;

  width: 100%;

  border-collapse: collapse;

  thead {
    @include font-700;
    border-top: 2px solid black;
    border-bottom: 1px solid black;
    th {
      padding: .5em 0;
      &:first-child {
        padding-left: 1em;
      }
      &:last-child {
        padding-right: 1em;
      }
    }
  }

  tbody {
    td {
      padding: .5em 0;
      border-bottom: 1px dashed #ddd;
      &:first-child {
        padding-left: 1em;
      }
      &:last-child {
        padding-right: 1em;
      }
    }
  }

  thead, tbody {
    font-size: 1.6em;
  }


  @media (max-width: 700px) {
    thead {
      display: none;
    }
    tbody tr {
      margin-bottom: 2em;
      border-top: 2px solid black;
    }
    tbody td {
      display: block;
      padding: .5em 1em;
      border: none;
      line-height: 1.2em;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      &:before {
        content: attr(data-th);
        @include font-700;
        width: 50%;
        display: inline-block;
      }
    }
    tbody td:last-child {
      margin-bottom: 1em;
    }
  }
}
