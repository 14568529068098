// REGULAR COLOR VARIABLES
// ==================================================
$regular-box-background:    white;

$regular-border:            #DDD9D5;
$regular-background:        $regular-box-background;
$regular-label:             #C5BFB8;
$regular-input:             #21262E;
$regular-border-active:     #21262E;
$regular-background-active: $regular-background;

// COMPACT COLOR VARIABLES
// ==================================================
$compact-box-background:    #F2F1F0;

$compact-border:            #D2CDC7;
$compact-background:        $compact-box-background;
$compact-label:             #C5BFB8;
$compact-input:             #25383D;
$compact-border-active:     $compact-border;
$compact-background-active: white;

// IMPORTS
// ==================================================

@import "placeholders";

.input {
  @import "text";
  @import "textarea";
  @import "number";
  @import "checkbox";
  @import "radio";
  @import "toggle";
  @import "tags";
  @import "dropdown";
}

// LAYOUT CONTAINERS
// ==================================================

.input__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0;

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }

  // Regular
  &:not(.-compact) {
    background: $regular-box-background;
    grid-gap: 2rem;
  }

  // Compact
  &.-compact {
    background: $compact-box-background;
    grid-gap: 1px;
  }
}
