&--radio {
  @extend %base-input;

  .radio__container {
    width: 100%;
    padding: 1.25em .5em .5em .75em;
  }

  .radio__group {
    padding: .25em 0;
    position: relative;

    display: flex;
    align-items: center;

    font-size: .85em;

    label {
      display: flex;
      align-items: center;

      &:before {
        content: '';
        display: block;
        height: .5em;
        width: .5em;
        border: 1px solid;
        border-radius: 100%;
        margin-right: .5em;
      }
    }
  }

  input[type="radio"] {
    @include invisible-input;
  }

  // SPECIAL STATES
  // ==================================================

  input[type="radio"]:checked ~ label {
    @include font-600;
    &:before {
      background-color: currentcolor;
    }
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    color: $regular-label;

    input[type="radio"]:checked ~ label {
      color: $regular-input;
    }
  }

  // Compact
  &.-compact {
    color: $compact-label;

    input[type="radio"]:checked ~ label {
      color: $compact-input;
    }
  }
}
