&--toggle {
  font-size: 1em;
  @include font-400;

  padding: 1.75em 1em;
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  position: relative;

  label {
    font-size: 1.5em;
    padding: .3em 0 .45em 0;
  }

  input[type="checkbox"] {
    @include invisible-input;
  }

  .slider {
    flex-shrink: 0;

    width: 3.5em;
    height: 2.4em;
    margin-right: 1em;
    border-radius: 10rem;

    background: red;// TODO

    transition: background 100ms;
    position: relative;

    &:after {
      position: absolute;
      display: block;
      content: '';

      height: 1.8em;
      width: 1.8em;
      border-radius: 50%;

      background: brown; // TODO
      top: .3em;
      margin-left: .4em;

      transition: all 100ms;
    }
  }

  // SPECIAL STATES
  // ==================================================

  input[type="checkbox"]:checked {
    & ~ .slider:after {
      margin-left: 1.3em;
    }
    & ~ label {
      @include font-600;
    }
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    padding-top: 1.25em;
    padding-bottom: 0;

    label {
      font-size: 2em;
      color: $regular-label;
    }

    .slider {
      font-size: 1.375em;
      background: $regular-label;
      &:after {
        background: $regular-background
      }
    }

    input[type="checkbox"]:checked {
      & ~ .slider {
        background: $regular-input;
      }
      & ~ label {
        color: $regular-input;
      }
    }

    &[data-has-focus="true"] {
      background: $regular-background-active;
      border-color: $regular-border-active;
    }
  }

  // Compact
  &.-compact {
    background: $compact-background;
    box-shadow: 0 0 0 1px $compact-border;

    label {
      color: $compact-label;
    }

    .slider {
      background: $compact-label;
      &:after {
        background: $compact-background
      }
    }

    input[type="checkbox"]:checked {
      & ~ .slider {
        background: $compact-input;
        &:after {
          background: $compact-background-active;
        }
      }
      & ~ label {
        color: $compact-input;
      }
    }

    &[data-has-focus="true"], &[data-has-content="true"] {
      background: $compact-background-active;
      box-shadow: 0 0 0 1px $compact-border-active;
      z-index: 1;
    }
  }
}
