%base-input {
  font-size: 2em;
  @include font-400;

  display: flex;
  align-items: stretch;
  position: relative;

  > label {
    @include font-mono-400;
    font-size: .5em;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: .12em;

    position: absolute;
    top: 0;
    left: 0;

    padding: 1em;
    padding-left: .8em;
  }

  // ENLARGE LABEL
  // - When the input field has no content
  // - When the input field has no :focus
  &[data-has-content="false"] > input:not(:focus) + label {
    font-size: 1em;
    letter-spacing: .06em;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 .5em;
  }

  // THEMING
  // ==================================================

  // Regular
  &:not(.-compact) {
    background: $regular-background;
    border: 2px solid $regular-border;
    border-radius: .2em;

    label {
      color: $regular-label;
    }

    &[data-has-focus="true"] {
      background: $regular-background-active;
      border-color: $regular-border-active;
    }
  }

  // Compact
  &.-compact {
    background: $compact-background;
    box-shadow: 0 0 0 1px $compact-border;

    label {
      color: $compact-label;
    }

    &[data-has-focus="true"], &[data-has-content="true"] {
      background: $compact-background-active;
      box-shadow: 0 0 0 1px $compact-border-active;
      z-index: 1;
    }
  }
}

%input {
  @include input-reset;

  @include font-400;
  font-size: 1em;
  line-height: 1;

  box-sizing: border-box;
  width: 100%;
  padding: 1.25em .5em .5em .75em;
}
